<template>
  <v-row class="mx-0 mt-md-3 mx-md-8 mx-lg-16">
    <v-card width="815" class="mx-sm-1 mb-4 mt-sm-4" flat>
      <v-card-title>
        {{ newEventTitle() }}
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-form ref="newEventForm">
          <v-text-field
            v-model="event.name"
            label="Name"
            :rules="[v => !!v || 'Name benötigt']"
          />
          <v-divider class="my-5" />
          <div class="font-weight-bold subtitle-1 mt-2">
            Zeitraum des Events
          </div>
          <v-checkbox label="mehrtägig?" v-model="multipleDays" />
          <v-row class="mx-0">
            <v-menu
              v-model="startPickerMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="event.startDate"
                  :label="multipleDays ? 'Start-Datum' : 'Datum'"
                  prepend-icon="event"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="[v => !!v || 'Bitte Datum angeben']"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="event.startDate"
                @input="startPickerMenu = false"
              ></v-date-picker>
            </v-menu>
            <v-menu
              v-if="multipleDays"
              v-model="endPickerMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="event.endDate"
                  label="End-Datum"
                  prepend-icon="event"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  class="ml-sm-4"
                  :rules="[v => (multipleDays && !!v) || 'Bitte Datum angeben']"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="event.endDate"
                @input="endPickerMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-row>
          <v-divider class="my-5" />
          <div class="font-weight-bold subtitle-1 mt-2">Ort des Events</div>
          <v-checkbox
            label="ortsunabhängiges Event"
            v-model="event.locationIndependent"
          />
          <v-expand-transition>
            <div style="overflow: hidden" v-if="!event.locationIndependent">
              <v-icon style="float: left; margin-top: 20px" v-if="!isMobile">
                place
              </v-icon>
              <div class="ml-sm-2" style="float: left;">
                <v-row class="mx-0 px-0">
                  <v-text-field
                    label="Name des Ortes"
                    v-model="event.eventLocation.name"
                    :rules="[
                      v =>
                        (!event.locationIndependent && !!v) ||
                        'Bitte Name des Ortes angeben.'
                    ]"
                  />
                </v-row>
                <v-row class="mx-0 px-0">
                  <v-text-field
                    label="Straße"
                    v-model="event.eventLocation.street"
                    :rules="[
                      v =>
                        (!event.locationIndependent && !!v) ||
                        'Bitte Straße angeben.'
                    ]"
                  />
                </v-row>
                <v-row class="mx-0 px-0">
                  <v-col class="col-3 mx-0 px-0">
                    <v-text-field
                      label="PLZ"
                      v-model="event.eventLocation.postalCode"
                      :rules="[
                        v => (!event.locationIndependent && !!v) || 'PLZ fehlt.'
                      ]"
                    />
                  </v-col>
                  <v-col class="col-9 pl-3 mx-0 px-0">
                    <v-text-field
                      label="Ort"
                      v-model="event.eventLocation.city"
                      :rules="[
                        v =>
                          (!event.locationIndependent && !!v) ||
                          'Bitte Ort angeben.'
                      ]"
                    />
                  </v-col>
                </v-row>
                <v-row class="mx-0 px-0">
                  <v-text-field
                    label="Land"
                    v-model="event.eventLocation.country"
                    :rules="[
                      v =>
                        (!event.locationIndependent && !!v) ||
                        'Bitte Land angeben.'
                    ]"
                  />
                </v-row>
              </div>
            </div>
          </v-expand-transition>
          <v-divider class="my-5" />
          <div class="font-weight-bold subtitle-1 mt-2">Treffpunkt</div>
          <v-menu
            ref="startTimePicker"
            v-model="startTimePickerMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="event.startTime"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="event.startTime"
                label="Start-Uhrzeit"
                prepend-icon="access_time"
                readonly
                v-bind="attrs"
                v-on="on"
                :rules="[v => !!v || 'Bitte Start-Uhrzeit angeben.']"
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="startTimePickerMenu"
              v-model="event.startTime"
              full-width
              format="24hr"
              scrollable
              @click:minute="$refs.startTimePicker.save(event.startTime)"
            ></v-time-picker>
          </v-menu>
          <div
            @click="
              event.meetingPointLocationDefined = !event.meetingPointLocationDefined
            "
            style="cursor: pointer"
          >
            <v-scroll-x-transition mode="out-in">
              <v-icon :key="event.meetingPointLocationDefined">
                {{ event.meetingPointLocationDefined ? "remove" : "add" }}
              </v-icon>
            </v-scroll-x-transition>
            <v-btn text class="ml-1">
              {{
                event.meetingPointLocationDefined
                  ? "Ort entfernen"
                  : "Ort hinzufügen"
              }}
            </v-btn>
          </div>
          <v-expand-transition>
            <div
              style="overflow: hidden"
              v-if="event.meetingPointLocationDefined"
            >
              <v-icon style="float: left; margin-top: 20px" v-if="!isMobile">
                place
              </v-icon>
              <div class="ml-sm-2" style="float: left">
                <v-row class="mx-0 px-0">
                  <v-text-field
                    label="Name des Ortes"
                    v-model="event.meetingPointLocation.name"
                    :rules="[
                      v =>
                        (!event.locationIndependent && !!v) ||
                        'Bitte Name des Ortes angeben.'
                    ]"
                  />
                </v-row>
                <v-row class="mx-0 px-0">
                  <v-text-field
                    label="Straße"
                    v-model="event.meetingPointLocation.street"
                    :rules="[
                      v =>
                        (event.meetingPointLocationDefined && !!v) ||
                        'Bitte Straße angeben'
                    ]"
                  />
                </v-row>
                <v-row class="mx-0 px-0">
                  <v-col class="col-3 mx-0 px-0">
                    <v-text-field
                      label="PLZ"
                      v-model="event.meetingPointLocation.postalCode"
                      :rules="[
                        v =>
                          (event.meetingPointLocationDefined && !!v) ||
                          'PLZ fehlt.'
                      ]"
                    />
                  </v-col>
                  <v-col class="col-9 pl-3 mx-0 px-0">
                    <v-text-field
                      label="Ort"
                      v-model="event.meetingPointLocation.city"
                      :rules="[
                        v =>
                          (event.meetingPointLocationDefined && !!v) ||
                          'Bitte Ort angeben.'
                      ]"
                    />
                  </v-col>
                </v-row>
                <v-row class="mx-0 px-0">
                  <v-text-field
                    label="Land"
                    v-model="event.meetingPointLocation.country"
                    :rules="[
                      v =>
                        (event.meetingPointLocationDefined && !!v) ||
                        'Bitte Land angeben.'
                    ]"
                  />
                </v-row>
                <v-btn
                  @click="
                    event.meetingPointLocation = JSON.parse(
                      JSON.stringify(event.eventLocation)
                    )
                  "
                  text
                  class="mb-2"
                >
                  Event Ort als Treffpunkt verwenden
                </v-btn>
              </div>
            </div>
          </v-expand-transition>
          <v-divider class="my-5" />
          <div class="font-weight-bold subtitle-1 mt-2">Event-Ende</div>
          <v-menu
            ref="endTimePicker"
            v-model="endTimePickerMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="event.endTime"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="event.endTime"
                label="End-Uhrzeit"
                prepend-icon="access_time"
                readonly
                v-bind="attrs"
                v-on="on"
                :rules="[v => !!v || 'Bitte End-Uhrzeit angeben.']"
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="endTimePickerMenu"
              v-model="event.endTime"
              full-width
              format="24hr"
              scrollable
              @click:minute="$refs.endTimePicker.save(event.endTime)"
            ></v-time-picker>
          </v-menu>
          <v-checkbox
            label="Teilnehmer werden nach Hause gebracht"
            v-model="event.participantsBroughtHome"
          />
          <v-expand-transition>
            <div
              v-if="!event.participantsBroughtHome"
              @click="
                event.pickupPointLocationDefined = !event.pickupPointLocationDefined
              "
              style="cursor: pointer"
            >
              <v-scroll-x-transition mode="out-in">
                <v-icon :key="event.pickupPointLocationDefined">
                  {{ event.pickupPointLocationDefined ? "remove" : "add" }}
                </v-icon>
              </v-scroll-x-transition>
              <v-btn text class="ml-1" :key="event.pickupPointLocationDefined">
                {{
                  event.pickupPointLocationDefined
                    ? "Ort entfernen"
                    : "Ort hinzufügen"
                }}
              </v-btn>
            </div>
          </v-expand-transition>
          <v-expand-transition>
            <div
              style="overflow: hidden"
              v-if="
                !event.participantsBroughtHome &&
                  event.pickupPointLocationDefined
              "
            >
              <v-icon style="float: left; margin-top: 20px" v-if="!isMobile">
                place
              </v-icon>

              <div class="ml-sm-2" style="float: left">
                <v-row class="mx-0 px-0">
                  <v-text-field
                    label="Name des Ortes"
                    v-model="event.pickupPointLocation.name"
                    :rules="[
                      v =>
                        (!event.locationIndependent && !!v) ||
                        'Bitte Name des Ortes angeben.'
                    ]"
                  />
                </v-row>
                <v-row class="mx-0 px-0">
                  <v-text-field
                    label="Straße"
                    v-model="event.pickupPointLocation.street"
                    :rules="[
                      v =>
                        (!event.participantsBroughtHome &&
                          event.pickupPointLocationDefined &&
                          !!v) ||
                        'Bitte Straße angeben'
                    ]"
                  />
                </v-row>
                <v-row class="mx-0 px-0">
                  <v-col class="col-3 mx-0 px-0">
                    <v-text-field
                      label="PLZ"
                      v-model="event.pickupPointLocation.postalCode"
                      :rules="[
                        v =>
                          (!event.participantsBroughtHome &&
                            event.pickupPointLocationDefined &&
                            !!v) ||
                          'PLZ fehlt.'
                      ]"
                    />
                  </v-col>
                  <v-col class="col-9 pl-3 mx-0 px-0">
                    <v-text-field
                      label="Ort"
                      v-model="event.pickupPointLocation.city"
                      :rules="[
                        v =>
                          (!event.participantsBroughtHome &&
                            event.pickupPointLocationDefined &&
                            !!v) ||
                          'Bitte Ort angeben.'
                      ]"
                    />
                  </v-col>
                </v-row>
                <v-row class="mx-0 px-0">
                  <v-text-field
                    label="Land"
                    v-model="event.pickupPointLocation.country"
                    :rules="[
                      v =>
                        (!event.participantsBroughtHome &&
                          event.pickupPointLocationDefined &&
                          !!v) ||
                        'Bitte Land angeben.'
                    ]"
                  />
                </v-row>
                <v-btn
                  @click="
                    event.pickupPointLocation = JSON.parse(
                      JSON.stringify(event.eventLocation)
                    )
                  "
                  text
                  class="mb-2"
                >
                  Event Ort als Abholort verwenden
                </v-btn>
              </div>
            </div>
          </v-expand-transition>
          <v-divider class="my-5" />
          <div class="font-weight-bold subtitle-1 mt-2">Teilnehmerpreis</div>
          <v-checkbox
            label="Teilnahme kostenlos"
            v-model="event.freeOfCharge"
          />
          <v-expand-transition>
            <div v-if="!event.freeOfCharge">
              <v-text-field
                label="Preis"
                prepend-icon="euro"
                inputmode="numeric"
                v-money="moneyConfig"
                v-model.lazy="event.price"
                :rules="[
                  v => (!event.freeOfCharge && !!v) || 'Bitte Preis angeben.'
                ]"
              />
            </div>
          </v-expand-transition>
          <v-divider class="my-5" />
          <div class="font-weight-bold subtitle-1 mt-2">
            Beschreibung des Events
          </div>
          <text-editor ref="textEditor" />
          <v-divider class="my-5" />
          <div class="font-weight-bold subtitle-1 mt-2">Anmeldung</div>
          <v-checkbox
            v-model="event.registrationPossible"
            label="Online-Anmeldung"
          />
          <v-expand-transition>
            <div v-if="event.registrationPossible" class="ml-5">
              <h3>Anmeldefrist</h3>
              <v-menu
                v-model="deadlinePickerMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="event.registrationInformation.registrationDeadline"
                    label="Anmeldeschluss"
                    prepend-icon="event"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :rules="[v => !!v || 'Bitte Datum angeben']"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="event.registrationInformation.registrationDeadline"
                  @input="deadlinePickerMenu = false"
                ></v-date-picker>
              </v-menu>
              <h3>Optionen der Onlineanmeldung</h3>
              <v-checkbox
                v-model="event.registrationInformation.registrationModuleDiet"
                hide-details
                label="Ernährungs Daten abfragen"
              />
              <v-checkbox
                v-model="
                  event.registrationInformation.registrationModuleMedicalData
                "
                hide-details
                label="Medizinische Daten abfragen"
              />
              <v-checkbox
                v-model="
                  event.registrationInformation.registrationModuleSwimming
                "
                hide-details
                label="Schwimmen abfragen"
              />
              <v-checkbox
                v-model="
                  event.registrationInformation.registrationModulePocketMoney
                "
                hide-details
                label="Taschengeld abfragen"
              />
            </div>
          </v-expand-transition>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="cancel()">
          Abbrechen
        </v-btn>
        <v-btn color="success" @click="create()" v-if="!editMode">
          Erstellen
        </v-btn>
        <v-btn color="success" @click="save()" v-else>
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-row>
</template>

<script>
import { VMoney } from "v-money";
import { mapActions } from "vuex";
import TextEditor from "../components/utility/TextEditor";
import Parser from "@/utils/Parser";

export default {
  name: "EventEditor",
  directives: { money: VMoney },
  components: {
    "text-editor": TextEditor
  },
  data() {
    return {
      event: {
        id: undefined,
        name: "",
        startDate: "",
        endDate: "",
        locationIndependent: false,
        eventLocation: {
          name: "",
          street: "",
          postalCode: "",
          city: "",
          country: ""
        },
        startTime: "",
        meetingPointLocationDefined: false,
        meetingPointLocation: {
          name: "",
          street: "",
          postalCode: "",
          city: "",
          country: ""
        },
        endTime: "",
        participantsBroughtHome: false,
        pickupPointLocationDefined: false,
        pickupPointLocation: {
          name: "",
          street: "",
          postalCode: "",
          city: "",
          country: ""
        },
        description: "",
        freeOfCharge: true,
        price: 0,
        registrationPossible: false,
        registrationInformation: {
          registrationDeadline: "",
          registrationModuleDiet: false,
          registrationModuleMedicalData: false,
          registrationModuleSwimming: false,
          registrationModulePocketMoney: false
        }
      },
      multipleDays: false,
      startPickerMenu: false,
      endPickerMenu: false,
      startTimePickerMenu: false,
      endTimePickerMenu: false,
      deadlinePickerMenu: false,
      moneyConfig: {
        decimal: ",",
        thousands: ".",
        suffix: " €",
        precision: 2
      }
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    editMode() {
      return this.$route.params.eventId;
    }
  },
  mounted() {
    if (this.editMode) {
      this.event = {
        ...this.$store.state.events.events.find(
          event => event.id === parseInt(this.$route.params.eventId)
          // parse int bug: only works with parse int. route.params return number when using goto,
          // but a string on page reload maybe vue router bug
        )
      };
      if (this.event.startDate !== this.event.endDate) {
        this.multipleDays = true;
      }
      this.event.price = Parser.numberToString(this.event.price);
      this.$refs.textEditor.setContent(this.event.description);
    }
  },
  methods: {
    ...mapActions("events", ["addEvent", "updateEvent"]),
    cancel() {
      this.$router.go(-1);
    },
    create() {
      if (!this.validateEvent()) {
        return;
      }
      let event = this.createEventObject();
      this.addEvent(event);
      this.$router.go(-1);
    },
    save() {
      if (!this.validateEvent()) {
        return;
      }
      let event = this.createEventObject();
      this.updateEvent(event);
      this.$router.go(-1);
    },
    validateEvent() {
      if (!this.$refs.newEventForm.validate()) {
        this.$vuetify.goTo(1, { duration: 1000 });
        return false;
      } else {
        return true;
      }
    },
    createEventObject() {
      let event = JSON.parse(JSON.stringify(this.event));
      if (!this.multipleDays) {
        event.endDate = event.startDate;
      }
      if (this.event.freeOfCharge) {
        event.price = 0;
      }
      if (this.event.locationIndependent) {
        event.eventLocation = this.nullLocation();
      }
      if (!this.event.meetingPointLocationDefined) {
        event.meetingPointLocation = this.nullLocation();
      }
      if (
        !this.event.pickupPointLocationDefined ||
        this.event.participantsBroughtHome
      ) {
        event.pickupPointLocation = this.nullLocation();
      }
      event.description = this.$refs.textEditor.getHTML();
      event.price = Parser.DEstringToNumber(this.event.price);
      return event;
    },
    newEventTitle() {
      return this.event.name
        ? this.event.name +
            (this.event.startDate
              ? " " + new Date(this.event.startDate).getFullYear()
              : "")
        : "Neues Event";
    },
    nullLocation() {
      return {
        name: "",
        street: "",
        postalCode: "",
        city: "",
        country: ""
      };
    }
  }
};
</script>

<style scoped></style>
