var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"mx-0 mt-md-3 mx-md-8 mx-lg-16"},[_c('v-card',{staticClass:"mx-sm-1 mb-4 mt-sm-4",attrs:{"width":"815","flat":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.newEventTitle())+" ")]),_c('v-divider'),_c('v-card-text',[_c('v-form',{ref:"newEventForm"},[_c('v-text-field',{attrs:{"label":"Name","rules":[function (v) { return !!v || 'Name benötigt'; }]},model:{value:(_vm.event.name),callback:function ($$v) {_vm.$set(_vm.event, "name", $$v)},expression:"event.name"}}),_c('v-divider',{staticClass:"my-5"}),_c('div',{staticClass:"font-weight-bold subtitle-1 mt-2"},[_vm._v(" Zeitraum des Events ")]),_c('v-checkbox',{attrs:{"label":"mehrtägig?"},model:{value:(_vm.multipleDays),callback:function ($$v) {_vm.multipleDays=$$v},expression:"multipleDays"}}),_c('v-row',{staticClass:"mx-0"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.multipleDays ? 'Start-Datum' : 'Datum',"prepend-icon":"event","readonly":"","rules":[function (v) { return !!v || 'Bitte Datum angeben'; }]},model:{value:(_vm.event.startDate),callback:function ($$v) {_vm.$set(_vm.event, "startDate", $$v)},expression:"event.startDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.startPickerMenu),callback:function ($$v) {_vm.startPickerMenu=$$v},expression:"startPickerMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.startPickerMenu = false}},model:{value:(_vm.event.startDate),callback:function ($$v) {_vm.$set(_vm.event, "startDate", $$v)},expression:"event.startDate"}})],1),(_vm.multipleDays)?_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"ml-sm-4",attrs:{"label":"End-Datum","prepend-icon":"event","readonly":"","rules":[function (v) { return (_vm.multipleDays && !!v) || 'Bitte Datum angeben'; }]},model:{value:(_vm.event.endDate),callback:function ($$v) {_vm.$set(_vm.event, "endDate", $$v)},expression:"event.endDate"}},'v-text-field',attrs,false),on))]}}],null,false,3468544091),model:{value:(_vm.endPickerMenu),callback:function ($$v) {_vm.endPickerMenu=$$v},expression:"endPickerMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.endPickerMenu = false}},model:{value:(_vm.event.endDate),callback:function ($$v) {_vm.$set(_vm.event, "endDate", $$v)},expression:"event.endDate"}})],1):_vm._e()],1),_c('v-divider',{staticClass:"my-5"}),_c('div',{staticClass:"font-weight-bold subtitle-1 mt-2"},[_vm._v("Ort des Events")]),_c('v-checkbox',{attrs:{"label":"ortsunabhängiges Event"},model:{value:(_vm.event.locationIndependent),callback:function ($$v) {_vm.$set(_vm.event, "locationIndependent", $$v)},expression:"event.locationIndependent"}}),_c('v-expand-transition',[(!_vm.event.locationIndependent)?_c('div',{staticStyle:{"overflow":"hidden"}},[(!_vm.isMobile)?_c('v-icon',{staticStyle:{"float":"left","margin-top":"20px"}},[_vm._v(" place ")]):_vm._e(),_c('div',{staticClass:"ml-sm-2",staticStyle:{"float":"left"}},[_c('v-row',{staticClass:"mx-0 px-0"},[_c('v-text-field',{attrs:{"label":"Name des Ortes","rules":[
                    function (v) { return (!_vm.event.locationIndependent && !!v) ||
                      'Bitte Name des Ortes angeben.'; }
                  ]},model:{value:(_vm.event.eventLocation.name),callback:function ($$v) {_vm.$set(_vm.event.eventLocation, "name", $$v)},expression:"event.eventLocation.name"}})],1),_c('v-row',{staticClass:"mx-0 px-0"},[_c('v-text-field',{attrs:{"label":"Straße","rules":[
                    function (v) { return (!_vm.event.locationIndependent && !!v) ||
                      'Bitte Straße angeben.'; }
                  ]},model:{value:(_vm.event.eventLocation.street),callback:function ($$v) {_vm.$set(_vm.event.eventLocation, "street", $$v)},expression:"event.eventLocation.street"}})],1),_c('v-row',{staticClass:"mx-0 px-0"},[_c('v-col',{staticClass:"col-3 mx-0 px-0"},[_c('v-text-field',{attrs:{"label":"PLZ","rules":[
                      function (v) { return (!_vm.event.locationIndependent && !!v) || 'PLZ fehlt.'; }
                    ]},model:{value:(_vm.event.eventLocation.postalCode),callback:function ($$v) {_vm.$set(_vm.event.eventLocation, "postalCode", $$v)},expression:"event.eventLocation.postalCode"}})],1),_c('v-col',{staticClass:"col-9 pl-3 mx-0 px-0"},[_c('v-text-field',{attrs:{"label":"Ort","rules":[
                      function (v) { return (!_vm.event.locationIndependent && !!v) ||
                        'Bitte Ort angeben.'; }
                    ]},model:{value:(_vm.event.eventLocation.city),callback:function ($$v) {_vm.$set(_vm.event.eventLocation, "city", $$v)},expression:"event.eventLocation.city"}})],1)],1),_c('v-row',{staticClass:"mx-0 px-0"},[_c('v-text-field',{attrs:{"label":"Land","rules":[
                    function (v) { return (!_vm.event.locationIndependent && !!v) ||
                      'Bitte Land angeben.'; }
                  ]},model:{value:(_vm.event.eventLocation.country),callback:function ($$v) {_vm.$set(_vm.event.eventLocation, "country", $$v)},expression:"event.eventLocation.country"}})],1)],1)],1):_vm._e()]),_c('v-divider',{staticClass:"my-5"}),_c('div',{staticClass:"font-weight-bold subtitle-1 mt-2"},[_vm._v("Treffpunkt")]),_c('v-menu',{ref:"startTimePicker",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.event.startTime,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.event, "startTime", $event)},"update:return-value":function($event){return _vm.$set(_vm.event, "startTime", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Start-Uhrzeit","prepend-icon":"access_time","readonly":"","rules":[function (v) { return !!v || 'Bitte Start-Uhrzeit angeben.'; }]},model:{value:(_vm.event.startTime),callback:function ($$v) {_vm.$set(_vm.event, "startTime", $$v)},expression:"event.startTime"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.startTimePickerMenu),callback:function ($$v) {_vm.startTimePickerMenu=$$v},expression:"startTimePickerMenu"}},[(_vm.startTimePickerMenu)?_c('v-time-picker',{attrs:{"full-width":"","format":"24hr","scrollable":""},on:{"click:minute":function($event){return _vm.$refs.startTimePicker.save(_vm.event.startTime)}},model:{value:(_vm.event.startTime),callback:function ($$v) {_vm.$set(_vm.event, "startTime", $$v)},expression:"event.startTime"}}):_vm._e()],1),_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.event.meetingPointLocationDefined = !_vm.event.meetingPointLocationDefined}}},[_c('v-scroll-x-transition',{attrs:{"mode":"out-in"}},[_c('v-icon',{key:_vm.event.meetingPointLocationDefined},[_vm._v(" "+_vm._s(_vm.event.meetingPointLocationDefined ? "remove" : "add")+" ")])],1),_c('v-btn',{staticClass:"ml-1",attrs:{"text":""}},[_vm._v(" "+_vm._s(_vm.event.meetingPointLocationDefined ? "Ort entfernen" : "Ort hinzufügen")+" ")])],1),_c('v-expand-transition',[(_vm.event.meetingPointLocationDefined)?_c('div',{staticStyle:{"overflow":"hidden"}},[(!_vm.isMobile)?_c('v-icon',{staticStyle:{"float":"left","margin-top":"20px"}},[_vm._v(" place ")]):_vm._e(),_c('div',{staticClass:"ml-sm-2",staticStyle:{"float":"left"}},[_c('v-row',{staticClass:"mx-0 px-0"},[_c('v-text-field',{attrs:{"label":"Name des Ortes","rules":[
                    function (v) { return (!_vm.event.locationIndependent && !!v) ||
                      'Bitte Name des Ortes angeben.'; }
                  ]},model:{value:(_vm.event.meetingPointLocation.name),callback:function ($$v) {_vm.$set(_vm.event.meetingPointLocation, "name", $$v)},expression:"event.meetingPointLocation.name"}})],1),_c('v-row',{staticClass:"mx-0 px-0"},[_c('v-text-field',{attrs:{"label":"Straße","rules":[
                    function (v) { return (_vm.event.meetingPointLocationDefined && !!v) ||
                      'Bitte Straße angeben'; }
                  ]},model:{value:(_vm.event.meetingPointLocation.street),callback:function ($$v) {_vm.$set(_vm.event.meetingPointLocation, "street", $$v)},expression:"event.meetingPointLocation.street"}})],1),_c('v-row',{staticClass:"mx-0 px-0"},[_c('v-col',{staticClass:"col-3 mx-0 px-0"},[_c('v-text-field',{attrs:{"label":"PLZ","rules":[
                      function (v) { return (_vm.event.meetingPointLocationDefined && !!v) ||
                        'PLZ fehlt.'; }
                    ]},model:{value:(_vm.event.meetingPointLocation.postalCode),callback:function ($$v) {_vm.$set(_vm.event.meetingPointLocation, "postalCode", $$v)},expression:"event.meetingPointLocation.postalCode"}})],1),_c('v-col',{staticClass:"col-9 pl-3 mx-0 px-0"},[_c('v-text-field',{attrs:{"label":"Ort","rules":[
                      function (v) { return (_vm.event.meetingPointLocationDefined && !!v) ||
                        'Bitte Ort angeben.'; }
                    ]},model:{value:(_vm.event.meetingPointLocation.city),callback:function ($$v) {_vm.$set(_vm.event.meetingPointLocation, "city", $$v)},expression:"event.meetingPointLocation.city"}})],1)],1),_c('v-row',{staticClass:"mx-0 px-0"},[_c('v-text-field',{attrs:{"label":"Land","rules":[
                    function (v) { return (_vm.event.meetingPointLocationDefined && !!v) ||
                      'Bitte Land angeben.'; }
                  ]},model:{value:(_vm.event.meetingPointLocation.country),callback:function ($$v) {_vm.$set(_vm.event.meetingPointLocation, "country", $$v)},expression:"event.meetingPointLocation.country"}})],1),_c('v-btn',{staticClass:"mb-2",attrs:{"text":""},on:{"click":function($event){_vm.event.meetingPointLocation = JSON.parse(
                    JSON.stringify(_vm.event.eventLocation)
                  )}}},[_vm._v(" Event Ort als Treffpunkt verwenden ")])],1)],1):_vm._e()]),_c('v-divider',{staticClass:"my-5"}),_c('div',{staticClass:"font-weight-bold subtitle-1 mt-2"},[_vm._v("Event-Ende")]),_c('v-menu',{ref:"endTimePicker",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.event.endTime,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.event, "endTime", $event)},"update:return-value":function($event){return _vm.$set(_vm.event, "endTime", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"End-Uhrzeit","prepend-icon":"access_time","readonly":"","rules":[function (v) { return !!v || 'Bitte End-Uhrzeit angeben.'; }]},model:{value:(_vm.event.endTime),callback:function ($$v) {_vm.$set(_vm.event, "endTime", $$v)},expression:"event.endTime"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.endTimePickerMenu),callback:function ($$v) {_vm.endTimePickerMenu=$$v},expression:"endTimePickerMenu"}},[(_vm.endTimePickerMenu)?_c('v-time-picker',{attrs:{"full-width":"","format":"24hr","scrollable":""},on:{"click:minute":function($event){return _vm.$refs.endTimePicker.save(_vm.event.endTime)}},model:{value:(_vm.event.endTime),callback:function ($$v) {_vm.$set(_vm.event, "endTime", $$v)},expression:"event.endTime"}}):_vm._e()],1),_c('v-checkbox',{attrs:{"label":"Teilnehmer werden nach Hause gebracht"},model:{value:(_vm.event.participantsBroughtHome),callback:function ($$v) {_vm.$set(_vm.event, "participantsBroughtHome", $$v)},expression:"event.participantsBroughtHome"}}),_c('v-expand-transition',[(!_vm.event.participantsBroughtHome)?_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.event.pickupPointLocationDefined = !_vm.event.pickupPointLocationDefined}}},[_c('v-scroll-x-transition',{attrs:{"mode":"out-in"}},[_c('v-icon',{key:_vm.event.pickupPointLocationDefined},[_vm._v(" "+_vm._s(_vm.event.pickupPointLocationDefined ? "remove" : "add")+" ")])],1),_c('v-btn',{key:_vm.event.pickupPointLocationDefined,staticClass:"ml-1",attrs:{"text":""}},[_vm._v(" "+_vm._s(_vm.event.pickupPointLocationDefined ? "Ort entfernen" : "Ort hinzufügen")+" ")])],1):_vm._e()]),_c('v-expand-transition',[(
              !_vm.event.participantsBroughtHome &&
                _vm.event.pickupPointLocationDefined
            )?_c('div',{staticStyle:{"overflow":"hidden"}},[(!_vm.isMobile)?_c('v-icon',{staticStyle:{"float":"left","margin-top":"20px"}},[_vm._v(" place ")]):_vm._e(),_c('div',{staticClass:"ml-sm-2",staticStyle:{"float":"left"}},[_c('v-row',{staticClass:"mx-0 px-0"},[_c('v-text-field',{attrs:{"label":"Name des Ortes","rules":[
                    function (v) { return (!_vm.event.locationIndependent && !!v) ||
                      'Bitte Name des Ortes angeben.'; }
                  ]},model:{value:(_vm.event.pickupPointLocation.name),callback:function ($$v) {_vm.$set(_vm.event.pickupPointLocation, "name", $$v)},expression:"event.pickupPointLocation.name"}})],1),_c('v-row',{staticClass:"mx-0 px-0"},[_c('v-text-field',{attrs:{"label":"Straße","rules":[
                    function (v) { return (!_vm.event.participantsBroughtHome &&
                        _vm.event.pickupPointLocationDefined &&
                        !!v) ||
                      'Bitte Straße angeben'; }
                  ]},model:{value:(_vm.event.pickupPointLocation.street),callback:function ($$v) {_vm.$set(_vm.event.pickupPointLocation, "street", $$v)},expression:"event.pickupPointLocation.street"}})],1),_c('v-row',{staticClass:"mx-0 px-0"},[_c('v-col',{staticClass:"col-3 mx-0 px-0"},[_c('v-text-field',{attrs:{"label":"PLZ","rules":[
                      function (v) { return (!_vm.event.participantsBroughtHome &&
                          _vm.event.pickupPointLocationDefined &&
                          !!v) ||
                        'PLZ fehlt.'; }
                    ]},model:{value:(_vm.event.pickupPointLocation.postalCode),callback:function ($$v) {_vm.$set(_vm.event.pickupPointLocation, "postalCode", $$v)},expression:"event.pickupPointLocation.postalCode"}})],1),_c('v-col',{staticClass:"col-9 pl-3 mx-0 px-0"},[_c('v-text-field',{attrs:{"label":"Ort","rules":[
                      function (v) { return (!_vm.event.participantsBroughtHome &&
                          _vm.event.pickupPointLocationDefined &&
                          !!v) ||
                        'Bitte Ort angeben.'; }
                    ]},model:{value:(_vm.event.pickupPointLocation.city),callback:function ($$v) {_vm.$set(_vm.event.pickupPointLocation, "city", $$v)},expression:"event.pickupPointLocation.city"}})],1)],1),_c('v-row',{staticClass:"mx-0 px-0"},[_c('v-text-field',{attrs:{"label":"Land","rules":[
                    function (v) { return (!_vm.event.participantsBroughtHome &&
                        _vm.event.pickupPointLocationDefined &&
                        !!v) ||
                      'Bitte Land angeben.'; }
                  ]},model:{value:(_vm.event.pickupPointLocation.country),callback:function ($$v) {_vm.$set(_vm.event.pickupPointLocation, "country", $$v)},expression:"event.pickupPointLocation.country"}})],1),_c('v-btn',{staticClass:"mb-2",attrs:{"text":""},on:{"click":function($event){_vm.event.pickupPointLocation = JSON.parse(
                    JSON.stringify(_vm.event.eventLocation)
                  )}}},[_vm._v(" Event Ort als Abholort verwenden ")])],1)],1):_vm._e()]),_c('v-divider',{staticClass:"my-5"}),_c('div',{staticClass:"font-weight-bold subtitle-1 mt-2"},[_vm._v("Teilnehmerpreis")]),_c('v-checkbox',{attrs:{"label":"Teilnahme kostenlos"},model:{value:(_vm.event.freeOfCharge),callback:function ($$v) {_vm.$set(_vm.event, "freeOfCharge", $$v)},expression:"event.freeOfCharge"}}),_c('v-expand-transition',[(!_vm.event.freeOfCharge)?_c('div',[_c('v-text-field',{directives:[{name:"money",rawName:"v-money",value:(_vm.moneyConfig),expression:"moneyConfig"}],attrs:{"label":"Preis","prepend-icon":"euro","inputmode":"numeric","rules":[
                function (v) { return (!_vm.event.freeOfCharge && !!v) || 'Bitte Preis angeben.'; }
              ]},model:{value:(_vm.event.price),callback:function ($$v) {_vm.$set(_vm.event, "price", $$v)},expression:"event.price"}})],1):_vm._e()]),_c('v-divider',{staticClass:"my-5"}),_c('div',{staticClass:"font-weight-bold subtitle-1 mt-2"},[_vm._v(" Beschreibung des Events ")]),_c('text-editor',{ref:"textEditor"}),_c('v-divider',{staticClass:"my-5"}),_c('div',{staticClass:"font-weight-bold subtitle-1 mt-2"},[_vm._v("Anmeldung")]),_c('v-checkbox',{attrs:{"label":"Online-Anmeldung"},model:{value:(_vm.event.registrationPossible),callback:function ($$v) {_vm.$set(_vm.event, "registrationPossible", $$v)},expression:"event.registrationPossible"}}),_c('v-expand-transition',[(_vm.event.registrationPossible)?_c('div',{staticClass:"ml-5"},[_c('h3',[_vm._v("Anmeldefrist")]),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Anmeldeschluss","prepend-icon":"event","readonly":"","rules":[function (v) { return !!v || 'Bitte Datum angeben'; }]},model:{value:(_vm.event.registrationInformation.registrationDeadline),callback:function ($$v) {_vm.$set(_vm.event.registrationInformation, "registrationDeadline", $$v)},expression:"event.registrationInformation.registrationDeadline"}},'v-text-field',attrs,false),on))]}}],null,false,2440388093),model:{value:(_vm.deadlinePickerMenu),callback:function ($$v) {_vm.deadlinePickerMenu=$$v},expression:"deadlinePickerMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.deadlinePickerMenu = false}},model:{value:(_vm.event.registrationInformation.registrationDeadline),callback:function ($$v) {_vm.$set(_vm.event.registrationInformation, "registrationDeadline", $$v)},expression:"event.registrationInformation.registrationDeadline"}})],1),_c('h3',[_vm._v("Optionen der Onlineanmeldung")]),_c('v-checkbox',{attrs:{"hide-details":"","label":"Ernährungs Daten abfragen"},model:{value:(_vm.event.registrationInformation.registrationModuleDiet),callback:function ($$v) {_vm.$set(_vm.event.registrationInformation, "registrationModuleDiet", $$v)},expression:"event.registrationInformation.registrationModuleDiet"}}),_c('v-checkbox',{attrs:{"hide-details":"","label":"Medizinische Daten abfragen"},model:{value:(
                _vm.event.registrationInformation.registrationModuleMedicalData
              ),callback:function ($$v) {_vm.$set(_vm.event.registrationInformation, "registrationModuleMedicalData", $$v)},expression:"\n                event.registrationInformation.registrationModuleMedicalData\n              "}}),_c('v-checkbox',{attrs:{"hide-details":"","label":"Schwimmen abfragen"},model:{value:(
                _vm.event.registrationInformation.registrationModuleSwimming
              ),callback:function ($$v) {_vm.$set(_vm.event.registrationInformation, "registrationModuleSwimming", $$v)},expression:"\n                event.registrationInformation.registrationModuleSwimming\n              "}}),_c('v-checkbox',{attrs:{"hide-details":"","label":"Taschengeld abfragen"},model:{value:(
                _vm.event.registrationInformation.registrationModulePocketMoney
              ),callback:function ($$v) {_vm.$set(_vm.event.registrationInformation, "registrationModulePocketMoney", $$v)},expression:"\n                event.registrationInformation.registrationModulePocketMoney\n              "}})],1):_vm._e()])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" Abbrechen ")]),(!_vm.editMode)?_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){return _vm.create()}}},[_vm._v(" Erstellen ")]):_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Speichern ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }